.select-error__control {
	border-color: $danger !important;
}

.react-select-custom {
	.select__control {
		border-radius: 16px !important;
	}

	&.select-error {
		.select__control {
			border-color: $danger !important;
		}
	}
}

// react-select-dddd__control

.react-select-default {
	.react-select__control {
		border-radius: 0.35rem;
		min-height: 35px;
		background-color: #f8f9fa;
	}

	.react-select__placeholder {
		color: rgba($dark, 0.7);
		font-weight: 600;
	}

	.react-select__indicator {
		padding-top: 3px !important;
		padding-bottom: 3px !important;
	}
}
