.card-sm {
	.card-header {
		min-height: auto;
		padding-top: 1rem;
		padding-bottom: 0.5rem;
	}

	.card-body {
		padding-top: 0;
	}
}
