//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.card-login {
	// background-color: #ededed;
	box-shadow: none;
	.card-body {
		padding: 0;
	}

	.img-wrapper {
		display: none;

		@include media-breakpoint-up(md) {
			display: block;
			padding-left: 100px;
			padding-top: 100px;
			padding-bottom: 100px;
			background-color: #ededed;
			border-top-left-radius: 26px;
			border-bottom-left-radius: 26px;
		}
	}

	.img-fluid {
		height: 568px;
		object-fit: cover;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.form-floating {
		.form-control {
			background-color: transparent;
			border: 0;
			box-shadow: none;
			border-radius: 0;
			border-bottom: 1px solid #1b4b51;
		}
		.form-control .my_input {
			color: #1b4b51 !important;
		}
		.form-label {
			color: #1b4b51 !important;
		}
	}

	.login-content-wrapper {
		height: 100%;
		width: 100%;
		background-color: #dde6f1; // #00809a;

		@include media-breakpoint-up(md) {
			padding-top: 100px;
			padding-bottom: 100px;
			padding-right: 100px;
			border-top-right-radius: 26px;
			border-bottom-right-radius: 26px;
		}
	}

	.login-content {
		padding: 40px;
		background-color: #dde6f1; // #00809a;

		box-shadow: 7px 3px 22px -4px rgba(0, 0, 0, 0.64);
		-webkit-box-shadow: 7px 3px 22px -4px rgba(0, 0, 0, 0.64);
		-moz-box-shadow: 7px 3px 22px -4px rgba(0, 0, 0, 0.64);
		position: relative;
		z-index: 10;
		border-radius: 8px;

		// img {
		// 	background-color: $white;
		// }
	}
}
